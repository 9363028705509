// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Private } from "@redwoodjs/router";
import InternalLayout from "src/layouts/InternalLayout/InternalLayout";
import NumbersToolLayout from "src/layouts/NumbersToolLayout/NumbersToolLayout";
import ThemisContextLayout from "src/layouts/ThemisContextLayout/ThemisContextLayout";
import { Roles } from "./utils/roles";

const Routes = () => {
  return (
    <Router>
      <Route path="/number-tools-background-job-status" page={NumberToolsBackgroundJobStatusPage} name="numberToolsBackgroundJobStatus" />
      <Route path="/number-tools-background-jobs" page={NumberToolsBackgroundJobsPage} name="numberToolsBackgroundJobs" />
      <Route path="/500" page={FatalErrorPage} name="fatalError" />
      <Route path="/redirect/{envelopeId:String}" page={DocusignRedirectPage} name="redirect" />
      <Route path="/login" page={LoginPage} name="login" />

      {/* --- Cloud Telephony Admin site --- */}
      <Private unauthenticated="login" wrap={ThemisContextLayout}>
        <Route path="/" page={HomePage} name="home" />
        <Route path="/profile" page={ProfilePage} name="profile" />
        <Route path="/support" page={SupportPage} name="support" />
        <Route path="/tenant-connection" page={TenantConnectionPage} name="tenantConnection" />
        <Route path="/sip-trunks" page={SipTrunksManagementPage} name="sipTrunksManagement" />

        <Private unauthenticated="home" roles={[Roles.GlobalAdmin, Roles.TelephonyAdmin, Roles.TelephonyAdminRead]}>
          <Route path="/phone-users" page={ProvisioningPage} name="provisioning" />

          <Route path="/numbers" redirect="/numbers/summary" />
          <Route path="/numbers/summary" page={NumbersSummaryPage} name="numbersSummary" />
          <Route path="/numbers/view-all" page={ViewAllNumbersPage} name="viewAllNumbers" />
          <Route path="/numbers/new-view-all" page={ViewAllNumbersV2Page} name="viewAllNumbersV2" />
          <Route path="/sites" page={SitesPage} name="sites" />
        </Private>

        <Private unauthenticated="home" roles={[Roles.GlobalAdmin, Roles.BillingAdmin, Roles.ReportingAdmin]}>
          <Route path="/billing" page={BillingPage} name="billing" />
          <Route path="/reporting/view-reports" page={ReportingPage} name="reporting" />
          <Route path="/reporting/dashboard" page={ReportingDashboardPage} name="reportingDashboard" />
          <Route path="/keys" page={ApiKeysPage} name="apiKeys" />
        </Private>

        <Private unauthenticated="home" roles={Roles.GlobalAdmin}>
          <Route path="/admin-access" page={AccessManagementPage} name="accessManagement" />
          <Route path="/tenant-connection" page={TenantConnectionPage} name="tenantAccess" />
          <Route path="/tenant-connection/setup" page={SetupTenantAccessPage} name="setupTenantAccess" />
        </Private>

        {/* --- Numbers Tool --- */}
        <Private unauthenticated="home" roles={["ctap:numbers-tool", "role:internal_support"]} wrap={NumbersToolLayout}>
          <Route path="/tools/numbers" page={NumbersToolHomePage} name="numbersToolHome" />
          <Route path="/tools/numbers/new" page={NumbersToolNewPage} name="numbersToolNew" />
          <Route path="/tools/numbers/create/{id:string}" page={NumbersToolCreatePage} name="numbersToolCreate" />
          <Route path="/tools/numbers/job/summary/{id:string}" page={NumbersToolJobSummaryPage} name="numbersToolJobSummary" />
          <Route path="/tools/numbers/saved-new" page={NumbersToolSavedNewPage} name="numbersToolSavedNew" />
          <Route path="/tools/numbers/backgroundjobs" page={NumberToolsBackgroundJobsPage} name="numbersToolBackgroundJobs" />
          <Route path="/tools/numbers/job-status/{id:string}" page={NumberToolsBackgroundJobStatusPage} name="numbersToolBackgroundJobStatus" />
        </Private>

        {/* --- Internal pages --- */}
        <Private unauthenticated="home" roles="ctap:internal" wrap={InternalLayout}>
          <Route path="/internal/debug" page={InternalDebugPage} name="debug" />
          <Route path="/internal/features" page={InternalFeaturesPage} name="internalFeatures" />
          <Route path="/internal/roles" page={InternalRolesPage} name="internalRoles" />
          <Route path="/internal/masquerade" page={InternalMasqueradePage} name="internalMasquerade" />

          {/* Customers */}
          <Route path="/internal/customers" page={InternalCustomersPage} name="internalCustomers" />
          <Route path="/internal/customers/{id:String}" page={InternalShowCustomerPage} name="internalShowCustomer" />

          {/* Customers */}
          {/* <Route path="/internal/customers" page={InternalCustomersPage} name="internalCustomers" /> */}
          <Route path="/internal/logins/{id:String}" page={InternalShowLoginPage} name="internalShowLogin" />
        </Private>
      </Private>

      <Route notfound page={NotFoundPage} />
    </Router>
  );
};

export default Routes;
