import {
  AllocationStatus,
  HostType,
  NumberStatus,
  NumberType,
  OculeusSyncStatus,
  UserType,
  UsageType,
  RibbonSyncStatus,
  RoutingEngineSyncStatus,
} from "lib/web/numbers";
import GatewayClient from "lib/web/gateway";
import { t } from "src/i18n";
import teleformat from "src/utils/teleformat";
import {
  BasicRow,
  DEFAULT_PULL_STRATEGY,
  buildInsight,
  buildOrderer,
  createDataCache,
} from "src/utils/contexts/dataCache";
import { numbersFetch } from "src/utils/numbersProxyClient";

export interface NumberRow extends BasicRow {
  action: string;
  id: string;
  allocationStatus_t: string /* @computed */;
  allocationStatus: AllocationStatus;
  carrier: string;
  carrierId: number;
  country: string /* @computed */;
  countryCode: string;
  customer: string;
  customerId: string;
  displayNumber: string /* @computed */;
  drn: string;
  hostType_t: string /* @computed */;
  hostType: HostType;
  numberOwnedByLoopUp: boolean;
  numberStatus_t: string /* @computed */;
  numberStatus: NumberStatus;
  numberType_t: string /* @computed */;
  numberType: NumberType;
  oculeusSyncStatus_t: string /* @computed */;
  oculeusSyncStatus: OculeusSyncStatus;
  ribbonSyncStatus_t: string;
  ribbonSyncStatus: RibbonSyncStatus;
  site: string;
  siteId: string;
  upn: string;
  user: string;
  userType: UserType;
  userType_t: string;
  usageType: UsageType;
  usageType_t: string;
  sipTrunk: string;
  routingSyncTrackingEnabled: boolean;
  routingEngineSyncStatus_t: string /* @computed */;
  routingEngineSyncStatus: RoutingEngineSyncStatus;
  pendingChanges: any;
  createdAtTime: any;
  maxPollingTimeInMinutes: any;
}

let errorMessage = "";
let routingSyncTrackingFlag = false;
let abortController = new AbortController();

// Function to get cookie value by name
const getCookie = (name) => {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null; // Return null if the cookie is not found
};

async function reauthenticate() {
  await fetch("/api/reauthenticate").then((res) => {
    if (res.status === 500) {
      throw res.status;
    }
  });

  const accessToken = getCookie("hecate_access_token");

  return accessToken;
}

async function getNumbers(
  customerId,
  countryCode,
  rangeStart,
  rangeEnd,
  pageNumber,
  pageSize,
  gateway,
  numberList
) {
  const accessToken = await reauthenticate();

  gateway = new GatewayClient(accessToken);
  const response = await gateway.getNumbers(
    customerId,
    countryCode,
    rangeStart,
    rangeEnd,
    pageNumber,
    pageSize,
    numberList
  );

  return response;
}

async function getInprogressNumbers(
  gateway: GatewayClient,
  loginId,
  inProgressNumbers
): Promise<any> {
  let accessToken = await reauthenticate();
  const response: any = {};
  let retryCount = 0;
  const maxRetries = 3;

  while (retryCount < maxRetries) {
    try {
      gateway = new GatewayClient(accessToken);

      const numberList = inProgressNumbers.map((number) => number.id);

      await gateway.getInprogressNumbers(
        loginId,
        numberList,
        abortController,
        (latestUpdate: any) => {
          Object.assign(response, latestUpdate);
        }
      );
      return response;
    } catch (error) {
      if (error.message === "Operation cancelled") {
        throw error;
      }

      retryCount++;
      if (retryCount < maxRetries) {
        accessToken = await reauthenticate(); // Reauthenticate for retry
      } else {
        throw new Error("Failed to get in-progress numbers after retries");
      }
    }
  }
}

function excludeUnusedProperties(arr) {
  return arr.map((obj) => {
    const newObj = {};
    for (const prop in obj) {
      if (obj[prop] !== null && obj[prop] !== 0) {
        newObj[prop] = obj[prop];
      }
    }
    return newObj;
  });
}

function addPendingUpdatedNumber(inProgressList) {
  const mappedList = inProgressList.map((item) => {
    return {
      action: item.action.value,
      id: item.ddi,
      allocationStatus: item.allocationStatus,
      carrier: item.carrierName ? item.carrierName.value : null,
      carrierId: item.carrierId || null,
      countryCode: item.countryCode ? item.countryCode.value : null,
      customer: item.customerName ? item.customerName.value : null,
      customerId: item.customerId ? item.customerId.value : null,
      drn: item.drNumber ? item.drNumber.value : null,
      hostType: item.hostType || null,
      numberStatus: item.numberStatus || null,
      numberType: item.ddiNumberType || null,
      numberOwnedByLoopUp: item.numberOwnedByLoopUp
        ? item.numberOwnedByLoopUp.value
        : null,
      oculeusSyncStatus: item.oculeusSyncStatus ?? null,
      ribbonSyncStatus: item.ribbonSyncStatus ?? null,
      site: item.siteName ? item.siteName.value : null,
      siteId: item.siteId ? item.siteId.value : null,
      upn: item.userPrincipalName ? item.userPrincipalName.value : null,
      user: item.assignedUserName ? item.assignedUserName.value : null,
      userType: item.userType || null,
      usageType: item.usageType || null,
      sipTrunk: item.sipTrunkName ? item.sipTrunkName.value : null,
      routingEngineSyncStatus: item.routingEngineSyncStatus,
      createdAtTime: item.createdAtTime,
      maxPollingTimeInMinutes: item.maxPollingTimeInMinutes,
    };
  });

  return mappedList;
}

function addPendingCreatedNumber(inProgressList) {
  const mappedList = inProgressList.map((item) => ({
    action: item.action?.value ?? null,
    id: item.ddi,
    allocationStatus: item.allocationStatus,
    carrier: item.carrierName?.value ?? null,
    carrierId: item.carrierId,
    countryCode: item.countryCode?.value ?? null,
    customer: item.customerName?.value ?? null,
    customerId: item.customerId?.value ?? null,
    drn: item.drNumber?.value ?? null,
    hostType: item.hostType,
    numberStatus: item.numberStatus,
    numberType: item.ddiNumberType,
    numberOwnedByLoopUp: item.numberOwnedByLoopUp?.value ?? null,
    oculeusSyncStatus: item.oculeusSyncStatus ?? null,
    ribbonSyncStatus: item.ribbonSyncStatus ?? null,
    site: item.siteName?.value ?? null,
    siteId: item.siteId?.value ?? null,
    upn: item.userPrincipalName?.value ?? null,
    user: item.assignedUserName?.value ?? null,
    userType: item.userType,
    usageType: item.usageType,
    sipTrunk: item.sipTrunkName?.value ?? null,
    routingEngineSyncStatus: item.routingEngineSyncStatus,
    createdAtTime: item.createdAtTime,
    maxPollingTimeInMinutes: item.maxPollingTimeInMinutes,
  }));

  return mappedList;
}

function mapItemsList(itemsList) {
  const mappedList = itemsList.map((item) => ({
    action: "Exist",
    id: item.ddi,
    allocationStatus: item.allocationStatus,
    carrier: item.carrierName?.value ?? null,
    carrierId: item.carrierId,
    countryCode: item.countryCode?.value ?? null,
    customer: item.customerName?.value ?? null,
    customerId: item.customerId?.value ?? null,
    drn: item.drNumber?.value ?? null,
    hostType: item.hostType,
    numberStatus: item.numberStatus,
    numberType: item.ddiNumberType,
    numberOwnedByLoopUp: item.numberOwnedByLoopUp,
    oculeusSyncStatus: item.oculeusSyncStatus ?? null,
    ribbonSyncStatus: item.ribbonSyncStatus ?? null,
    site: item.siteName?.value ?? null,
    siteId: item.siteId?.value ?? null,
    upn: item.userPrincipalName?.value ?? null,
    user: item.assignedUserName?.value ?? null,
    userType: item.userType,
    usageType: item.usageType,
    sipTrunk: item.sipTrunkName?.value ?? null,
    routingEngineSyncStatus: item.routingEngineSyncStatus,
    pendingChanges: [],
  }));

  return mappedList;
}

function mergeObjects(updateObjects, existingObjects) {
  const merged = existingObjects.map((existing) => {
    const updateObject = updateObjects.find(
      (update) => update.id === existing.id
    );
    return updateObject ? { ...existing, ...updateObject } : existing;
  });

  return merged;
}

export const buildFetchInProgressData = () =>
  async function fetchData(
    {
      gateway,
    }: {
      gateway: GatewayClient;
    },
    loginId,
    inProgressList
  ): Promise<{ rows: NumberRow[] }> {
    let items;

    try {
      const response = await getInprogressNumbers(
        gateway,
        loginId,
        inProgressList
      );

      const pendingUpdatededNumber = [];
      const pendingCreatedNumber = [];

      for (const item of response.inProgressList) {
        if (item.action["value"] === "Update") {
          pendingUpdatededNumber.push(item);
        } else if (item.action["value"] === "Create") {
          pendingCreatedNumber.push(item);
        }
      }

      const pendingUpdatededNumberFinal = addPendingUpdatedNumber(
        pendingUpdatededNumber
      );

      const pendingCreatedNumberFinal =
        addPendingCreatedNumber(pendingCreatedNumber);

      items = [...pendingUpdatededNumberFinal, ...pendingCreatedNumberFinal];
    } catch (error) {
      if (error.message === "Operation cancelled") {
        throw error;
      }

      throw new Error("Failed to fetch pending changes data");
    }

    return {
      rows: items.map((item) => ({
        action: item.action,
        id: item.id,
        allocationStatus: item.allocationStatus,
        carrier: item.carrier,
        carrierId: item.carrierId,
        countryCode: item.countryCode,
        customer: item.customer,
        customerId: item.customerId,
        drn: item.drn,
        hostType: item.hostType,
        numberStatus: item.numberStatus,
        numberType: item.numberType,
        numberOwnedByLoopUp: item.numberOwnedByLoopUp,
        oculeusSyncStatus: item.oculeusSyncStatus,
        ribbonSyncStatus: item.ribbonSyncStatus,
        site: item.site,
        siteId: item.siteId,
        upn: item.upn,
        user: item.user,
        userType: item.userType,
        usageType: item.usageType,
        sipTrunk: item.sipTrunk,
        routingEngineSyncStatus: item.routingEngineSyncStatus,
        pendingChanges: item.pendingChanges,
        createdAtTime: item.createdAtTime,
        maxPollingTimeInMinutes: item.maxPollingTimeInMinutes,
      })),
    };
  };

function manageNumbers(numbers) {
  const inProgressNumbers = numbers.inProgressList.map((obj) => {
    return {
      ...obj,
      maxPollingTimeInMinutes: numbers.maxPollingTimeInMinutes["value"],
    };
  });

  let pendingUpdatedNumber = addPendingUpdatedNumber(
    inProgressNumbers.filter((item) => item.action["value"] === "Update")
  );

  pendingUpdatedNumber = excludeUnusedProperties(pendingUpdatedNumber).map(
    (obj) => {
      return {
        ...obj,
        pendingChanges: Object.keys(obj).filter(
          (key) => key !== "action" && key !== "id"
        ),
      };
    }
  );

  let pendingCreatedNumber = addPendingCreatedNumber(
    inProgressNumbers.filter((item) => item.action["value"] === "Create")
  );

  pendingCreatedNumber = excludeUnusedProperties(pendingCreatedNumber).map(
    (obj) => {
      return {
        ...obj,
        pendingChanges: Object.keys(obj).filter((key) => key !== "action"),
      };
    }
  );

  const existingItemsList = mapItemsList(numbers.itemsList);

  const mergedUpdateAndExising = mergeObjects(
    pendingUpdatedNumber,
    existingItemsList
  );

  return [...mergedUpdateAndExising, ...pendingCreatedNumber];
}

export const buildFetchData = (
  customerId: string,
  countryCode?: string,
  rangeStart?: string,
  rangeEnd?: string,
  numberList?: any
) =>
  async function fetchData(
    { gateway }: { gateway: GatewayClient },
    pageSize: number,
    pageNumber: number
  ): Promise<{
    hasNextPage: boolean;
    rows: NumberRow[];
  }> {
    let hasNextPage = false;
    let items;

    try {
      if (rangeStart && !rangeEnd) {
        try {
          const response = await getNumbers(
            customerId,
            countryCode,
            null,
            null,
            pageNumber,
            pageSize,
            gateway,
            [rangeStart]
          );
          items = manageNumbers(response);
          hasNextPage = false;
        } catch (error) {
          if (error.code === 13) {
            throw new Error("Error while getting the numbers");
          } else if (error.code === 14) {
            throw new Error("Numbers Api Service Unavailable");
          }

          throw new Error("Unexpected error happened");
        }
      } else if (
        (customerId && !rangeStart && !rangeEnd) ||
        (!customerId && rangeStart && rangeEnd) ||
        (customerId && rangeStart && rangeEnd) ||
        (!customerId && !rangeStart && !rangeEnd && countryCode) ||
        (numberList && numberList.length > 0)
      ) {
        try {
          const response = await getNumbers(
            customerId,
            countryCode,
            rangeStart,
            rangeEnd,
            pageNumber,
            pageSize,
            gateway,
            numberList
          );

          items = manageNumbers(response);

          hasNextPage = response.paginationInfo?.hasNextPage;
          if (
            routingSyncTrackingFlag !== true &&
            response.routingEngineSyncTrackingEnabled["value"] === true
          ) {
            routingSyncTrackingFlag = true;
          }
        } catch (error) {
          if (error.code === 13) {
            throw new Error("Error while getting the numbers");
          } else if (error.code === 14) {
            throw new Error("Numbers Api Service Unavailable");
          }

          throw new Error("Unexpected error happened");
        }
      } else {
        // Clear data if an invalid combination of search terms is passed
        errorMessage = "";
        return {
          hasNextPage: false,
          rows: [],
        };
      }
      errorMessage = "";
    } catch (error) {
      errorMessage = error.message;
      items = [];
      hasNextPage = false;
    }

    return {
      hasNextPage,
      rows: items.map((item) => ({
        action: item.action,
        id: item.id,
        allocationStatus: item.allocationStatus,
        carrier: item.carrier,
        carrierId: item.carrierId,
        countryCode: item.countryCode,
        customer: item.customer,
        customerId: item.customerId,
        drn: item.drn,
        hostType: item.hostType,
        numberStatus: item.numberStatus,
        numberType: item.numberType,
        numberOwnedByLoopUp: item.numberOwnedByLoopUp,
        oculeusSyncStatus: item.oculeusSyncStatus,
        ribbonSyncStatus: item.ribbonSyncStatus,
        site: item.site,
        siteId: item.siteId,
        upn: item.upn,
        user: item.user,
        userType: item.userType,
        usageType: item.usageType,
        sipTrunk: item.sipTrunk,
        routingEngineSyncStatus: item.routingEngineSyncStatus,
        pendingChanges: item.pendingChanges,
        createdAtTime: item.createdAtTime,
        maxPollingTimeInMinutes: item.maxPollingTimeInMinutes,
      })),
    };
  };

export async function fetchData(
  _,
  pageSize: number,
  pageNumber: number
): Promise<{ hasNextPage: boolean; rows: NumberRow[] }> {
  const res = await numbersFetch("GET", "/v1/numbers", {
    pageNumber,
    pageSize,
    rangeStart: "0",
    rangeEnd: "99999999999999999",
  });

  if (res.status >= 400 && res.status < 600) {
    errorMessage = "No results found";
    return { hasNextPage: false, rows: [] };
  }

  const { hasNextPage = false, items = [] } = res?.body ?? {};

  return {
    hasNextPage,
    rows: items.map((item) => ({
      id: item.ddi,
      allocationStatus: item.allocationStatus,
      carrier: item.carrier,
      carrierId: item.carrierId,
      countryCode: item.countryCode,
      customerId: item.customerId,
      customer: item.customer,
      drn: item.drNumber,
      hostType: item.hostType,
      numberStatus: item.numberStatus,
      numberType: item.geographyType,
      numberOwnedByLoopUp: item.numberOwnedByLoopUp,
      oculeusSyncStatus: item.oculeusSyncStatus,
      ribbonSyncStatus: item.ribbonSyncStatus,
      site: item.site,
      siteId: item.siteId,
      upn: item.upn,
      sipTrunk: item.sipTrunkName,
      routingEngineSyncStatus: item.routingEngineSyncStatus,
    })),
  };
}

const numbers = createDataCache<NumberRow>(
  "nt-numbers",
  buildFetchData(""),
  DEFAULT_PULL_STRATEGY,
  false // Do not store these numbers in indexdb
);

numbers.registerComputed("country", (row) => {
  row.country = t(`country_${row.countryCode}`);
});
numbers.registerComputed("displayNumber", (row) => {
  row.displayNumber = teleformat.decorate(`+${row.id}`).international;
});
numbers.registerComputed("allocationStatus_t", (row) => {
  row.allocationStatus_t = t(
    `allocationStatus${AllocationStatus[row.allocationStatus]}`
  );
});
numbers.registerComputed("hostType_t", (row) => {
  row.hostType_t = t(`hostType${HostType[row.hostType]}`);
});
numbers.registerComputed("numberStatus_t", (row) => {
  row.numberStatus_t = t(`numberStatus${NumberStatus[row.numberStatus]}`);
});
numbers.registerComputed("numberType_t", (row) => {
  row.numberType_t = t(`numberType${NumberType[row.numberType]}`);
});
numbers.registerComputed("oculeusSyncStatus_t", (row) => {
  if (row.oculeusSyncStatus < 1) {
    row.oculeusSyncStatus = 9;
  }
  row.oculeusSyncStatus_t = t(
    `oculeusSyncStatus${OculeusSyncStatus[row.oculeusSyncStatus]}`
  );
});
numbers.registerComputed("ribbonSyncStatus_t", (row) => {
  if (row.ribbonSyncStatus < 1) {
    row.ribbonSyncStatus = 5;
  }
  row.ribbonSyncStatus_t = t(
    `ribbonSyncStatus${RibbonSyncStatus[row.ribbonSyncStatus]}`
  );
});
numbers.registerComputed("routingEngineSyncStatus_t", (row) => {
  if (row.routingEngineSyncStatus < 1) {
    row.routingEngineSyncStatus = 1;
  }
  row.routingEngineSyncStatus_t = t(
    `routingEngineSyncStatus${
      RoutingEngineSyncStatus[row.routingEngineSyncStatus]
    }`
  );
});

numbers.registerComputed("usageType_t", (row) => {
  if (row.usageType < 1) {
    row.usageType = 4;
  }
  row.usageType_t = t(`usageType${UsageType[row.usageType]}`);
});

numbers.registerComputed("userType_t", (row) => {
  if (row.userType < 1) {
    row.userType = 8;
  }
  row.userType_t = t(`usageType${UserType[row.userType]}`);
});

numbers.registerInsight(
  "allocation_status_options",
  buildInsight<NumberRow>("property_enumeration")("allocationStatus")
);
numbers.registerInsight(
  "country_options",
  buildInsight<NumberRow>("property_enumeration")("countryCode")
);
numbers.registerInsight(
  "customer_options",
  buildInsight<NumberRow>("property_enumeration")("customer")
);
numbers.registerInsight(
  "customerId_options",
  buildInsight<NumberRow>("property_pairs")("customerId", "customer")
);
numbers.registerInsight(
  "host_type_options",
  buildInsight<NumberRow>("property_enumeration")("hostType")
);
numbers.registerInsight(
  "number_owned_by_loopup_options",
  buildInsight<NumberRow>("property_enumeration")("numberOwnedByLoopUp")
);
numbers.registerInsight(
  "number_status_options",
  buildInsight<NumberRow>("property_enumeration")("numberStatus")
);
numbers.registerInsight(
  "number_type_options",
  buildInsight<NumberRow>("property_enumeration")("numberType")
);
numbers.registerInsight(
  "oculeus_sync_status_options",
  buildInsight<NumberRow>("property_enumeration")("oculeusSyncStatus")
);
numbers.registerInsight(
  "routing_engine_sync_status_options",
  buildInsight<NumberRow>("property_enumeration")("routingEngineSyncStatus")
);
numbers.registerInsight(
  "site_options",
  buildInsight<NumberRow>("property_pairs")("siteId", "site")
);
numbers.registerInsight(
  "carrier_options",
  buildInsight<NumberRow>("property_pairs")("carrierId", "carrier")
);
numbers.registerInsight(
  "user_type_options",
  buildInsight<NumberRow>("property_enumeration")("userType")
);
numbers.registerInsight(
  "usage_type_options",
  buildInsight<NumberRow>("property_enumeration")("usageType")
);
/** Precomputed orderings */
numbers.registerOrderer(
  "orderby_number",
  buildOrderer<NumberRow>("single_property")("displayNumber")
);
numbers.registerOrderer(
  "orderby_allocationStatus",
  buildOrderer<NumberRow>("single_property")("allocationStatus_t")
);
numbers.registerOrderer(
  "orderby_carrier",
  buildOrderer<NumberRow>("single_property")("carrier")
);
numbers.registerOrderer(
  "orderby_country",
  buildOrderer<NumberRow>("single_property")("country")
);
numbers.registerOrderer(
  "orderby_customer",
  buildOrderer<NumberRow>("single_property")("customer")
);
numbers.registerOrderer(
  "orderby_drn",
  buildOrderer<NumberRow>("single_property")("drn")
);
numbers.registerOrderer(
  "orderby_hostType",
  buildOrderer<NumberRow>("single_property")("hostType_t")
);
numbers.registerOrderer(
  "orderby_numberStatus",
  buildOrderer<NumberRow>("single_property")("numberStatus_t")
);
numbers.registerOrderer(
  "orderby_numberType",
  buildOrderer<NumberRow>("single_property")("numberType_t")
);
numbers.registerOrderer(
  "orderby_oculeusSyncStatus",
  buildOrderer<NumberRow>("single_property")("oculeusSyncStatus_t")
);
numbers.registerOrderer(
  "orderby_routingEngineSyncStatus",
  buildOrderer<NumberRow>("single_property")("routingEngineSyncStatus_t")
);
numbers.registerOrderer(
  "orderby_ribbonSyncStatus",
  buildOrderer<NumberRow>("single_property")("ribbonSyncStatus")
);
numbers.registerOrderer(
  "orderby_sipTrunk",
  buildOrderer<NumberRow>("single_property")("sipTrunk")
);
numbers.registerOrderer(
  "orderby_site",
  buildOrderer<NumberRow>("single_property")("site")
);
numbers.registerOrderer(
  "orderby_upn",
  buildOrderer<NumberRow>("single_property")("upn")
);
numbers.registerOrderer(
  "orderby_numberOwnedByLoopUp",
  buildOrderer<NumberRow>("single_property")("numberOwnedByLoopUp")
);
numbers.registerOrderer(
  "orderby_numberUnFormatted",
  buildOrderer<NumberRow>("single_property")("id")
);
numbers.registerOrderer(
  "orderby_assignedUser",
  buildOrderer<NumberRow>("single_property")("user")
);
numbers.registerOrderer(
  "orderby_userType",
  buildOrderer<NumberRow>("single_property")("userType")
);
numbers.registerOrderer(
  "orderby_usageType",
  buildOrderer<NumberRow>("single_property")("usageType")
);

export const Provider = numbers.provider;
export const registerNumbersInsight = numbers.registerInsight;
export const registerNumbersView = numbers.registerOrderer;
export const useNumbers = numbers.useData;
export const useNumbersInfo = numbers.useInfo;
export const useNumbersInsight = numbers.useInsight;
export const getErrorMessage = () => {
  return errorMessage;
};
export const getRoutingSyncFlag = () => {
  return routingSyncTrackingFlag;
};
export const abortGetInprogress = () => {
  abortController.abort();
  abortController = new AbortController();
};
